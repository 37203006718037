import crm from "@/services/crm/config/menu.js";
import developer from "@/services/developer/config/menu.js";
import event from "@/services/event/config/menu.js";
import hrdc from "@/services/hrdc/config/menu.js";
import hrdc_einvoicing from "@/services/hrdc_einvoicing/config/menu.js";
import hrdc_tax_summit from "@/services/hrdc_tax_summit/config/menu.js";
import lead from "@/services/lead/config/menu.js";
import log from "@/services/log/config/menu.js";
import marketing from "@/services/marketing/config/menu.js";
import product from "@/services/product/config/menu.js";
import profiling from "@/services/profiling/config/menu.js";
import sales from "@/services/sales/config/menu.js";
import sso from "@/services/sso/config/menu.js";
import testing from "@/services/testing/config/menu.js";

export default {
  main: [
    {
      name: "My Workspace",
    },
    {
      name: "My Leads",
      icon: "mdi-database",
      route: {
        name: "PageServiceModelBrowse",
        params: { serviceKey: "lead", modelKey: "myLead" },
      },
      disabled: true,
    },
    {
      type: "divider",
    },
  ],
  crm,
  developer,
  event,
  hrdc,
  hrdc_einvoicing,
  hrdc_tax_summit,
  lead,
  log,
  marketing,
  product,
  profiling,
  sales,
  sso,
  testing,
};
