export const EDIT_APPLICATION = 1;
export const DELETE_APPLICATION = 2;
export const APPLICATION_SUBMITTED = 3;
export const APPLICATION_APPROVED = 4;
export const QUOTATION_DATE_SCHEDULED = 5;
export const SUBSCRIPTION_DATE_SCHEDULED = 6;
export const SENT_JD14_FORM_TO_CLIENT = 7;
export const SENT_T3_FORM_TO_CLIENT = 8;
export const SENT_CONFIRMATION_EMAIL_TO_CLIENT = 9;
export const ALERT_HRDC_ADMIN_TO_RESEND_EMAIL_TO_CLIENT = 10;
export const GRANT_APPROVAL_NO_SUBMITTED = 11;
export const INCOMPLETE_GRANT_APPROVAL_AND_REVERT_FORM = 12;
export const CLIENT_REVERT_JD14_FORM = 13;
export const CLIENT_REVERT_T3_FORM = 14;
export const INVOICE_DATE_SCHEDULED = 15;
export const MANUAL_INVOICE_TO_HRDC = 16;
export const SUBMITTED_CLAIM_TO_HRDC = 17;
export const CLAIM_APPROVED_FROM_HRDC = 18;
export const PAYMENT_RECEIVED_FROM_HRDC = 19;
export const CREDIT_NOTE_REQUESTED = 20;
export const CREDIT_NOTE_DOCUMENTED = 21;
export const REFUND_EMAIL_SUBMISSION = 22;
export const REFUND_EMAIL_UPDATED = 23;
export const REFUND_EMAIL_APPROVAL_1 = 24;
export const REFUND_EMAIL_APPROVAL_2 = 25;
export const REFUND_EMAIL_AR_TO_AP = 26;
export const AP_SUBMIT_REFUND_TO_CIMB = 27;
export const REFUND_FAILED = 28;
export const FINANCE_REFUND_TO_CLIENT = 29;
export const EMAIL_PAYMENT_ADVICE_TO_CLIENT = 30;
export const SENT_REFUND_EMAIL = 31;
export const TAXPOD_ACCOUNT_ACTIVATION = 32;
export const CLAIM_COMPLETED = 33;
export const AUTO_GENERATED_TAXPOD_INVOICE = 34;
export const CREDIT_NOTE = 35;
export const PAYMENT_ADVICE_FROM_HRDC = 36;
export const PAYMENT_ADVICE_FROM_CLIENT = 37;
export const APPLICATION_DEAD = 38;
export const APPLICATION_DUPLICATE = 39;
export const REFUND_EMAIL_UPDATED_2 = 40;
export const REFUND_EMAIL_OTHER_ATTACHMENTS = 41;
export const REFUND_EMAIL_APPROVAL_1_REJECTED = 42;
export const REFUND_EMAIL_APPROVAL_2_REJECTED = 43;
export const REFUND_EMAIL_AR_TO_AP_REJECTED = 44;
export const PAYMENT_RECEIVED_FROM_HRDC_REJECTED = 45;
export const JD14_T3_FORM_SUBMITTED = 46;
export const INCOMPLETE_GRANT_APPROVED = 47;
export const INCOMPLETE_FORMS_COMPLETED = 48;
export const CREDIT_NOTE_GENERATED = 49;
export const GENERAL_INFO_REMARKS = 50;
export const PAYMENT_RECEIVED_FROM_HRDC_2 = 51;
export const GENERAL_ATTACHMENT = 52;
export const NORMAL_FLAG = 53;
export const NORMAL_UNFLAG = 54;
export const FLAG_REMARKS = 55;
export const APPLICATION_DUPLICATED = 56;
export const HRDC_GRANT_EMAIL = 57;
export const REFUND_EMAIL_AP_TO_CIMB_REJECTED = 58;
export const PAYMENT_VOUCHER = 59;

// const stages = [
//   {
//     name: "Edit Application",
//     nameSlug: "editApplication",
//     stagePriority: 1,
//   },
//   {
//     name: "Delete Application",
//     nameSlug: "deleteApplication",
//     stagePriority: 2,
//   },
//   {
//     name: "Application Submitted",
//     nameSlug: "applicationSubmitted",
//     stagePriority: 100,
//   },
//   {
//     name: "Application Approved",
//     nameSlug: "applicationApproved",
//     stagePriority: 200,
//   },
//   {
//     name: "Quotation Date Scheduled",
//     nameSlug: "quotationDateScheduled",
//     stagePriority: 300,
//   },
//   {
//     name: "Subscription Date Scheduled",
//     nameSlug: "subscriptionDateScheduled",
//     stagePriority: 400,
//   },
//   {
//     name: "Sent JD14 Form To Client",
//     nameSlug: "sentJd14FormToClient",
//     stagePriority: 500,
//   },
//   {
//     name: "Sent T3 Form To Client",
//     nameSlug: "sentT3FormToClient",
//     stagePriority: 600,
//   },
//   {
//     name: "Sent Confirmation Email To Client",
//     nameSlug: "sentConfirmationEmailToClient",
//     stagePriority: 700,
//   },
//   {
//     name: "Alert HRDC Admin To Resend Email To Client",
//     nameSlug: "alertHrdcAdminResendEmailToClient",
//     stagePriority: 800,
//   },
//   {
//     name: "Grant Approval No Submitted",
//     nameSlug: "grantApprovalNoSubmitted",
//     stagePriority: 900,
//   },
//   {
//     name: "(Incomplete) Grant Approval & Revert Form",
//     nameSlug: "clientRevertFormIncomplete",
//     stagePriority: 1000,
//   },
//   {
//     name: "Client Revert JD14 Form",
//     nameSlug: "clientRevertJd14Form",
//     stagePriority: 1100,
//   },
//   {
//     name: "Client Revert T3 Form",
//     nameSlug: "clientRevertT3Form",
//     stagePriority: 1200,
//   },
//   {
//     name: "Invoice Date Scheduled",
//     nameSlug: "invoiceDateScheduled",
//     stagePriority: 1300,
//   },
//   {
//     name: "Manual Invoice To HRDC",
//     nameSlug: "manualInvoiceToHrdc",
//     stagePriority: 1400,
//   },
//   {
//     name: "Submitted Claim to HRDC",
//     nameSlug: "submittedClaimToHrdc",
//     stagePriority: 1500,
//   },
//   {
//     name: "Claim Approved from HRDC",
//     nameSlug: "claimApprovedFromHrdc",
//     stagePriority: 1600,
//   },
//   {
//     name: "Payment Received from HRDC",
//     nameSlug: "paymentReceivedFromHrdc",
//     stagePriority: 1700,
//   },
//   {
//     name: "Credit Note Requested",
//     nameSlug: "creditNoteRequested",
//     stagePriority: 1800,
//   },
//   {
//     name: "Credit Note Documented",
//     nameSlug: "creditNoteDocumented",
//     stagePriority: 1900,
//   },
//   {
//     name: "Refund Email Submission",
//     nameSlug: "refundEmailSubmission",
//     stagePriority: 2000,
//   },
//   {
//     name: "Refund Email Updated",
//     nameSlug: "refundEmailUpdated",
//     stagePriority: 2100,
//   },
//   {
//     name: "Refund Email Approval 1",
//     nameSlug: "refundEmailApproval1",
//     stagePriority: 2200,
//   },
//   {
//     name: "Refund Email Approval 2",
//     nameSlug: "refundEmailApproval2",
//     stagePriority: 2300,
//   },

//   {
//     name: "Refund Email AR to AP",
//     nameSlug: "refundEmailArToAp",
//     stagePriority: 2400,
//   },

//   {
//     name: "AP Submit Refund to CIMB",
//     nameSlug: "apSubmitRefundToCimb",
//     stagePriority: 2450,
//   },

//   {
//     name: "Refund Failed",
//     nameSlug: "refundFailed",
//     stagePriority: 2500,
//   },
//   {
//     name: "Finance Refund to Client",
//     nameSlug: "financeRefundToClient",
//     stagePriority: 2600,
//   },
//   {
//     name: "Email Payment Advice to Client",
//     nameSlug: "emailPaymentAdviceToClient",
//     stagePriority: 2700,
//   },
//   {
//     name: "Sent Refund Email",
//     nameSlug: "sentRefundEmail",
//     stagePriority: 2800,
//   },
//   {
//     name: "TaxPOD Account Activation",
//     nameSlug: "taxpodAccountActivation",
//     stagePriority: 2900,
//   },
//   {
//     name: "Claim Completed",
//     nameSlug: "hrdcClaimCompleted",
//     stagePriority: 3000,
//   },
//   {
//     name: "Auto Generated TaxPOD Invoice",
//     nameSlug: "autoGeneratedTaxpodInvoice",
//     stagePriority: 2005,
//   },
//   {
//     name: "Credit Note",
//     nameSlug: "creditNote",
//     stagePriority: 2010,
//   },
//   {
//     name: "Payment Advice From HRDC",
//     nameSlug: "paymentAdviceFromHrdc",
//     stagePriority: 2015,
//   },
//   {
//     name: "Payment Advice From Client",
//     nameSlug: "paymentAdviceFromClient",
//     stagePriority: 2020,
//   },
//   {
//     name: "Application Dead",
//     nameSlug: "applicationDead",
//     stagePriority: 3,
//   },
//   {
//     name: "Application Duplicate",
//     nameSlug: "applicationDuplicate",
//     stagePriority: 4,
//   },
//   {
//     name: "Refund Email Updated 2",
//     nameSlug: "refundEmailUpdated2",
//     stagePriority: 2105,
//   },
//   {
//     name: "Refund Email Other Attachments",
//     nameSlug: "refundEmailOtherAttachments",
//     stagePriority: 2025,
//   },
//   {
//     name: "Refund Email Approval 1 Rejected",
//     nameSlug: "refundEmailApproval1Rejected",
//     stagePriority: 2195,
//   },
//   {
//     name: "Refund Email Approval 2 Rejected",
//     nameSlug: "refundEmailApproval2Rejected",
//     stagePriority: 2295,
//   },

//   {
//     name: "Refund Email AR to AP Rejected",
//     nameSlug: "refundEmailArToApRejected", //Refund Email AR to AP
//     stagePriority: 2395,
//   },

//   {
//     name: "Payment Received from HRDC Rejected",
//     nameSlug: "paymentReceivedFromHrdcRejected",
//     stagePriority: 2495,
//   },

//   {
//     name: "JD14 T3 Form Submitted",
//     nameSlug: "jd14T3FormSubmitted",
//     stagePriority: 901,
//   },
//   {
//     name: "(Incomplete) Grant Approved",
//     nameSlug: "grantApproved",
//     stagePriority: 920,
//   },
//   {
//     name: "(Incomplete) Forms Completed",
//     nameSlug: "formsCompleted",
//     stagePriority: 940,
//   },
//   {
//     name: "Credit Note Generated",
//     nameSlug: "creditNoteGenerated",
//     stagePriority: 1850,
//   },
//   {
//     name: "General Info Remarks",
//     nameSlug: "generalInfoRemarks",
//     stagePriority: 5,
//   },
//   {
//     name: "Payment Received from HRDC 2",
//     nameSlug: "paymentReceivedFromHrdc2",
//     stagePriority: 1699,
//   },
//   {
//     name: "General Attachment",
//     nameSlug: "generalAttachment",
//     stagePriority: 6,
//   },
//   {
//     name: "Normal Flag",
//     nameSlug: "normalFlag",
//     stagePriority: 7,
//   },
//   {
//     name: "Normal Unflag",
//     nameSlug: "normalUnflag",
//     stagePriority: 8,
//   },
//   {
//     name: "Flag Remarks",
//     nameSlug: "flagRemarks",
//     stagePriority: 11,
//   },
//   {
//     name: "Application Duplicated",
//     nameSlug: "applicationDuplicated",
//     stagePriority: 50,
//   },
//   {
//     name: "HRDC Grant Email",
//     nameSlug: "hrdcGrantEmail",
//     stagePriority: 880,
//   },
//   {
//     name: "Refund Email AP to CIMB Rejected",
//     nameSlug: "refundEmailApToCimbRejected",
//     stagePriority: 2440,
//   },
// ];

// export default stages;
